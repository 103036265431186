import React from "react"
import { css } from "@emotion/react"
import SEO from "../../components/bseo"
import BookNav from "../../components/BookNav"
import BookLayout from "../../components/BookLayout"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Img from "gatsby-image"

const Books = ({ data }) => {
  return (
    <BookLayout>
      <SEO title="Home"></SEO>
      <section className="hero">
        <div>
          <div>
            <h1>
              Book chapters <br />
              in your inbox
              <br /> on your schedule
            </h1>

            <Link
              css={css`
                margin-top: 20px;
                @media screen and (max-width: 800px) {
                  text-align: center;
                }
              `}
              to="/books/store"
            >
              Browse Books
            </Link>
          </div>
          <Img fluid={data.image4.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section
        css={css`
          background: #1b1b1b;
          color: #fafafa;
        `}
      >
        <div id="hard">
          <div>
            <h2>
              It’s <em>hard</em> to find time to read
            </h2>
            <p>
              So bring the books to you instead. With Blog Books you can get
              book chapters delivered to the inbox that you already check
              everyday. <br />
              <br /> Without changing your behavior, you can read more books
              this year.
            </p>
          </div>
          <Img fluid={data.image2.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section>
        {" "}
        <div>
          <div>
            <h2>Decide on your reading schedule</h2>
            <p>
              Get as much as you want in your inbox every day, week, or month.{" "}
              <br /> <br /> Read at the pace that makes sense for your reading
              goals.
            </p>
          </div>
          <Img fluid={data.image3.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section
        css={css`
          background: #1b1b1b;
          color: #fafafa;
        `}
      >
        <div>
          <div>
            <h2>Choose your inbox</h2>
            <h3>Email Newsletter</h3>
            <p>Newsletter addict? Get your books at the top of your inbox.</p>
            <h3
              css={css`
                margin-top: 20px !important;
              `}
            >
              RSS
            </h3>
            <p>
              RSS fan? Get your books delivered to your favorite RSS reader.
              (#ripgooglereader)
            </p>
          </div>
          <Img fluid={data.image4.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section>
        <div>
          <div>
            <h2>Make slow and steady progress to your reading goals</h2>
            <p>
              Make concrete progress towards reading more books by reading in
              bite-sized chunks.
            </p>
          </div>
          <Img fluid={data.image5.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section
        css={css`
          background: #1b1b1b;
          color: #fafafa;
        `}
      >
        <div className="bookshelf-preview">
          <h2>500 Books And Counting</h2>
          <h3>
            Timeless classics you've always wanted to read but never found the
            time for.
          </h3>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              a {
                all: unset;
                cursor: pointer;
              }
            `}
            className="bookshelf-preview-image-container"
          >
            <Link to="/books/siddhartha-hermann-hesse">
              <img src="https://standardebooks.org/images/covers/hermann-hesse_siddhartha_gunther-olesch_anke-dreher_amy-coulter_stefan-langer_semyon-chaichenets-d44df0e1-cover@2x.jpg"></img>
            </Link>
            <Link to="/books/the-art-of-war-sun-tzu">
              <img src="https://standardebooks.org/images/covers/sun-tzu_the-art-of-war_lionel-giles-638d9bcb-cover@2x.jpg"></img>
            </Link>
            <Link to="/books/war-and-peace-leo-tolstoy">
              <img src="https://standardebooks.org/images/covers/leo-tolstoy_war-and-peace_louise-maude_aylmer-maude-a1fe15a8-cover@2x.jpg"></img>
            </Link>
            {/* <Link to="/books/tao-te-ching-laozi">
              <img src="https://standardebooks.org/images/covers/laozi_tao-te-ching_james-legge-e120cd76-cover@2x.avif"></img>
            </Link> */}
            <Link to="/books/meditations-marcus-aurelius">
              <img src="https://standardebooks.org/images/covers/marcus-aurelius_meditations_george-long-4aee4819-cover@2x.jpg"></img>
            </Link>
          </div>
          <div
            css={css`
              margin-top: 35px;
              width: 100% !important;
              text-align: center;
            `}
          >
            <Link
              css={css`
                color: #fafafa !important;
                font-size: 24px !important;
              `}
              to="/books/store"
            >
              Browse Books
            </Link>
          </div>
        </div>
      </section>
      <section>
        <div className="faq">
          <h2>Frequently Asked Questions</h2>
          <p className="question">
            <strong>What books are available?</strong>
          </p>
          <p>
            Right now we are mostly limited to books in the public domain, but
            in the future we hope to have every book available in this new
            format.
          </p>
          <p>
            If you are an author who wants their book here, please{" "}
            <Link to="/books/contact">reach out</Link>.
          </p>
          <p className="question">
            <strong>How much does a book cost?</strong>
          </p>
          <p>
            Each book costs $4.99. Half of the profits from public domain books
            are donated to{" "}
            <a target="_blank" href="https://standardebooks.org/">
              Standard Ebooks
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://www.gutenberg.org/">
              Project Gutenberg
            </a>{" "}
            to help with their work in advancing the public domain.
          </p>
          <p className="question">
            <strong>What if I just want to read the books online?</strong>
          </p>
          <p>
            If you want to read public domain books on the web, e-reader, or
            audio, you should check out{" "}
            <a target="_blank" href="https://standardebooks.org/">
              Standard Ebooks
            </a>
            ,{" "}
            <a target="_blank" href="https://www.gutenberg.org/">
              Project Gutenberg
            </a>
            , or{" "}
            <a target="_blank" href="https://librivox.org">
              LibriVox
            </a>{" "}
            to get them in those formats for free.
          </p>

          <p className="question">
            <strong>Does my RSS reader support this?</strong>
          </p>
          <p>
            We use the standard RSS format so it should work with any RSS
            reader, but if there are issues you can let us know and we'll
            resolve it.
          </p>
          <p className="question">
            <strong>Who are you? How do I contact you?</strong>
          </p>
          <p>
            Hey there 👋🏾, I'm Dmitri. You can email me at dkb@dkb.fyi, or DM me
            on twitter (
            <a target="_blank" href="https://twitter.com/dkb868">
              @dkb868
            </a>
            ), if you have any questions, concerns, thoughts, or ideas.
          </p>
        </div>
      </section>
      <footer
        css={css`
          text-align: center;
          font-family: "Nunito", sans-serif;
          margin-bottom: 20px;
        `}
      >
        A{" "}
        <Link
          to="/"
          css={css`
            color: #323232;
          `}
        >
          Blog Surf
        </Link>{" "}
        Project 🏄
      </footer>
    </BookLayout>
  )
}

export default Books

export const query = graphql`
  query BookImageQuery {
    image1: file(relativePath: { eq: "full-email-text-img-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "email-inbox-img-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "schedule-img-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "rss-img-4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "inbox-img-5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
